<template>
    <div>
        <div v-if="class_knowledge && class_knowledge.knowledge_link" class="campus-schedule-mar">
            <van-cell title="上课资料" />
            <div class="campus-schedule-content">
                <div @click="toPath" class="campus-schedule-knowledge">
                    <van-icon name="down" />
                    <div style="margin-left:10px" class="van-ellipsis">
                        {{class_knowledge.knowledge_name}}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="detailInfo.class_remark" class="campus-schedule-mar">
            <van-cell title="上课备注" />
            <div class="campus-schedule-content">{{detailInfo.class_remark}}</div>
        </div>

        <div v-if="detailInfo.class_summary" class="schedule-detail-html campus-schedule-mar">
            <van-cell title="上课内容" />
            <div @click="toPreview($event)" class="ql-editor" v-html="html"></div>
        </div>

    </div>
</template>

<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import {addRandomAndGetPreviewImageUrlsFromRichText} from '@/utils/imgPreview'
    import { ImagePreview } from 'vant'
    export default {
        name:'campusScheduleDetail',
        data() {
            return {
                detailInfo:{},
                class_knowledge:{},
                course_class_id:'',
                html:'',
                richImages:[],
            }
        },
        created () {
            this.course_class_id = this.$route.query.course_class_id
            this.getDetail()
        },
        methods: {
            getDetail() {
                this.$api.schedule_summary({course_class_id:this.course_class_id})
                .then(res=>{
                    this.detailInfo = res.data
                    this.class_knowledge = res.data.class_knowledge
                    if(res.data.class_summary){
                        let arr = addRandomAndGetPreviewImageUrlsFromRichText(res.data.class_summary)
                        this.html = arr[0]
                        this.richImages = arr[1]
                    }
                })
            },
            toPath() {
                console.log(this.class_knowledge.file_ext_type)
                if(this.class_knowledge.file_ext_type === 'pdf' || this.class_knowledge.file_ext_type === 'mp3' || this.class_knowledge.file_ext_type === 'mp4'){
                    let link = this.class_knowledge.knowledge_link.split('?attname')[0]
                    console.log(link)
                    window.location.replace(link)
                }else if(this.class_knowledge.file_ext_type === 'ppt' || this.class_knowledge.file_ext_type === 'pptx' || this.class_knowledge.file_ext_type === 'xls' || this.class_knowledge.file_ext_type === 'xlsx' || this.class_knowledge.file_ext_type === 'docx'){
                    window.location.replace(`https://view.officeapps.live.com/op/view.aspx?src=${this.class_knowledge.knowledge_link}`)
                }else{
                    this.$toast('该类型文件暂不支持在线预览～')
                }
            },
            toPreview(e){
                if (e.target.tagName == 'IMG') {
                    // this.$api.click_index({event_id:this.detail.course_class_id,studio_id:this.detail.course_studio.studio_id,event_type:'course_class_summary'}).then(res=>{})
                    WeixinJSBridge.invoke("imagePreview", {
                        "urls":this.richImages,
                        "current": e.target.src.split('random=')[1] - 1
                    });
                    // ImagePreview({
                    //     images: this.richImages,
                    //     startPosition: e.target.src.split('random=')[1] - 1,
                    //     closeOnPopstate: true,//页面回退关闭预览
                    //     maxZoom: 9
                    // })
                }
            }

        },
    }
</script>

<style lang="less" scoped>
    .campus-schedule-mar{
        margin-top: 10px;
    }
    .campus-schedule-content{
        padding: 5px 10px;
    }
    .campus-schedule-knowledge{
        border: 1px dashed #00cca2;
        padding: 5px;
        color:#00cca2;
        display: flex;
        align-items: center;
    }
</style>

<style lang="less">
    .schedule-detail-html{
      img{
        max-width: 100%;
      }
    }
</style>